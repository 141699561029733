import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const MonthlyReportVisitorDoughnutChart = (inviteAttendedJoinRatio:any) => {

const data_value = inviteAttendedJoinRatio.inviteAttendedJoinRatio
const data = {
      labels: ['Sent Invitation', 'Visitor Attendance', 'Visitor Joined' ],
      datasets: [
        {
          label: '# of Votes',
          data: [data_value.invitedsum, data_value.attendedsum, data_value.joinedsum],
          backgroundColor: [
            'rgb(58, 161, 251)',
            'rgba(53, 203, 202)',
            'rgb(107, 205, 60)',
          ],
          borderColor: [
            'rgba(58, 161, 251, 1)',
            'rgba(53, 203, 202, 1)',
            'rgba(107, 205, 60, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    
   
      return <Doughnut data={data} />;
   
}

export default MonthlyReportVisitorDoughnutChart