// import React from "react";
import { Image } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { SummarysessionsListWrapper } from './summarysessions-list/SummarysessionsList'
import SummaryBarChart from './SummaryBarchart'
import SessionAttendancePieChart from './SessionAttendancePieChart'
import VisitorDoughnutChart from './VisitorDoughnutChart'
import React, { useContext, useEffect, useState } from 'react'
import { apiUrl } from '../../../apiurl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
// import * as FileSaver from 'file-saver';
import download from 'downloadjs'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import * as XLSX from 'xlsx'
import { QueryRequestProvider } from './summarysessions-list/core/QueryRequestProvider'
import { QueryResponseProvider } from './summarysessions-list/core/QueryResponseProvider'
import { useQueryRequest } from './summarysessions-list/core/QueryRequestProvider'

import {
  GroupsDropDown,
  GetAverageFeedback,
  GetAttendanceGraph,
  GetTrackSheetCount,
  GetInvitedAttendedJoinedRatio,
  GetInvitedAttended,
  TopPerformersData,
  ChapterName,
  SessionDates,
  getUserLeaderList,
} from './summarysessions-list/core/_requests'
import axios from 'axios'
import { checkDisplayValue, getFileUrl } from '../../../utils'
import { UserContext } from '../../../contextStore'
// import { FaFileImage, FaFilePdf } from 'react-icons/fa'
import { AiTwotoneFileAdd } from 'react-icons/ai'

const addSchema = Yup.object().shape({
  chapter_id: Yup.number().required('This field is required.'),
  event_id: Yup.number().required('This field is required.'),
  file: Yup.string().required('This field is required.'),
  // user_id: Yup.number().required('This field is required.'),
})

const SummarySessions = () => {
  const { state, updateState } = useQueryRequest() as any
  const API_URL = apiUrl
  const currentMonth = moment().month() + 1
  const currentYear = moment().year()
  const [selectChapterId, setSelectChapterId] = useState<any>('')
  const [selectSession, setSelectSession] = useState('')
  const [selectGroup, setSelectGroup] = useState('')
  const [selectLeader, setSelectLeader] = useState('')
  // const [selectYear, setSelectYear] = useState('')
  // const [selectMonth, setSelectMonth] = useState('')
  const [leaderFilterList, setLeaderFilterList] = useState([])
  const [chapterData, setChapterData] = useState([])
  const [leaderPoints, setLeaderPoints] = useState([])
  const [sessionData, setSessionData] = useState<any>([{}])
  const [groupData, setGroupData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [top5Performers, setTop5Performers] = useState([])
  const [datassr, setDatassr] = useState<any>([])
  const [datachapter, setDatachapter] = useState<any>([])
  const [dataevent, setDataevent] = useState<any>([])
  const [filenameadd, setFileName] = useState<any>('')
  const { userDetail, setMessage } = useContext(UserContext)
  const [isLoadingfile, setIsLoadingFile] = useState(false)
  const userId = userDetail?.id ?? ''
  const IMAGE_UPLOAD = `${API_URL}/containers/public/upload`
  const ADD_SSR_URL = `${API_URL}/sessionsummaryreports/addReport`
  const EDIT_SSR_URL = `${API_URL}/sessionsummaryreports/editReport`
  const CHAPTER_LIST = `${API_URL}/chapters?filter[where][isdelete]=0`
  const EVENT_LIST = `${API_URL}/eventdates?filter[where][isdelete]=0`
  const SSR_URL = `${API_URL}/sessionsummaryreports?filter[isdelete]=0&filter[include]=chapters&filter[include]=eventdate&filter[include]=user&filter[order]=created_at desc`

  // const [trackSheetCount, setTrackSheetCount] = useState({ tracksheetcount: '', notsubmitted: '' })
  const [conversionRatio, setConversionRatio] = useState<any>('')

  const [inviteAttendedJoin, setInviteAttendedJoin] = useState<any>({ visitorInvited: "", visitorAttendent: '', visitorJoined: '', sessionPresent: "", sessionAbsent: '', feedbackGiven: '', feedbacknotgiven: '', tracksheetSubmitted: '' })
  const [inviteAttendedJoinRatio, setInviteAttendedJoinRatio] = useState<any>({ visitorInvited: "", visitorAttendent: '', visitorJoined: '', invitedPercentage: '', attendedPercentage: '', joinedPercentage: '' })

  const [attandanceGraph, setAttendanceGraph] = useState({
    attendadantPercentage: '',
    absentPercentage: '',
  })

  const [getAverageFeedback, setGetAverageFeedback] = useState<any>({
    sumfor1va: '',
    sumfor2va: '',
    sumfor3va: '',
    sumfor4va: '',
    sumfor5va: '',
    sumfor1tyca: '',
    sumfor2tyca: '',
    sumfor3tyca: '',
    sumfor4tyca: '',
    sumfor5tyca: '',
    averageva: '',
    averagetyca: '',
    averagess: '',
    given: '',
    sumfor1ss: '',
    sumfor2ss: '',
    sumfor3ss: '',
    sumfor4ss: '',
    sumfor5ss: '',
  })

  const fetchLeaderList = async () => {
    try {
      await getUserLeaderList().then((res: any) => {
        if (res && res.length > 0) {
          setLeaderFilterList(res.filter((x: any) => x.chapter_id == 3 || x.chapter_id == 6) ?? [])
        }
      })
    }
    catch (err) {
      console.log("error", err)
    }
  }

  useEffect(() => {
    fetchLeaderList()
    fetchDatassr()
    fetchDatachapter()
    fetchDataevent()
    fetchSessionDetail()
  }, [])

  const fetchDatassr = async () => {
    try {
      const res = await axios.get(`${SSR_URL}`)
      setDatassr(res?.data ?? [])
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  const fetchDatachapter = async () => {
    try {
      const res = await axios.get(`${CHAPTER_LIST}`)
      setDatachapter(res?.data ?? [])
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  const fetchDataevent = async (chapter_id: any = "") => {
    try {
      let query = EVENT_LIST
      if (chapter_id) {
        query = query + `&filter[where][chapter_id]=${chapter_id}`
      }
      const res = await axios.get(query)
      setDataevent(res?.data ?? [])
    } catch (error) {
      console.log('Error -->:', error)
    }
  }

  const handleButtonClick = async (pdf: any) => {
    if (pdf) {
      let url = await getFileUrl(`${API_URL}/containers/sessionsummaryreport/download/${pdf}`,`${API_URL}/containers/public/download/${pdf}` as any) as any
      window.open(url, '_blank')
    }
  }

  const addValue = {
    chapter_id: '',
    event_id: '',
    file: '',
    user_id: userId,
  }

  const uploadFile = async (file: File) => {
    setIsLoadingFile(true)
    try {
      if (!file) {
        console.error('No file selected')
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      const imageuploadn = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setFileName(imageuploadn?.data?.result?.files?.file[0]?.name ?? '')
      setIsLoadingFile(false)
    } catch (error) {
      console.log(error, 'Something Went Wrong')
      setIsLoadingFile(false)
    }
  }

  const editFile = async (file: File, id: any) => {
    try {
      if (!file) {
        console.error('No file selected')
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      var imageupload = await axios.post(`${IMAGE_UPLOAD}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      const filename = imageupload?.data?.result?.files?.file[0]?.name ?? ''
      const values = { id: id, file: filename }

      await axios.post(EDIT_SSR_URL, values).then((res: any) => {
        if (res.status == 200) {
          setMessage('success', res?.data ?? 'File Uploaded.')
          fetchDatassr()
        } else {
          setMessage('error', res?.data ?? 'Failed to upload file.')
        }
      })
    } catch (error) {
      console.log(error, 'Something Went Wrong')
    }
  }

  const addformik = useFormik({
    initialValues: addValue,
    validationSchema: addSchema,
    onSubmit: async (values: any, { setSubmitting, resetForm }) => {
      setSubmitting(true)
      try {
        const postData = {
          chapter_id: values.chapter_id,
          event_id: values.event_id,
          file: filenameadd,
          user_id: userId,
        }
        await axios.post(ADD_SSR_URL, postData).then((res: any) => {
          if (res.status == 200) {
            setMessage('success', 'Session Summary added successfully.')
            setFileName('')
            resetForm()
            const fileInput = document.getElementById('inputGroupFile04') as any
            if (fileInput) {
              fileInput.value = ''
            }
            fetchDatassr()
          } else {
            setMessage('error', 'Something Went Wrong..')
          }
        })
        setSubmitting(false)
      } catch (error) {
        setMessage('error', 'Something Went Wrong.')
        setSubmitting(false)
      }
    },
  }) as any

  useEffect(() => {
    addformik.setFieldValue('file', filenameadd)
  }, [filenameadd])

  const fetchGetAverageFeedback = async () => {
    try {
      await GetAverageFeedback(selectChapterId, selectSession, selectGroup, selectLeader)
        // await GetAverageFeedback(query)
        .then((res: any) => {
          if (res) {
            setGetAverageFeedback(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchChapterDetail = async () => {
    try {
      await ChapterName()
        .then((res: any) => {
          if (res) {
            setChapterData(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchSessionDetail = async () => {
    try {
      await SessionDates(selectChapterId)
        .then((res: any) => {
          if (res) {
            setSessionData(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchGetAttendanceGraph = async () => {
    try {
      await GetAttendanceGraph(selectChapterId, selectSession, selectGroup, selectLeader)
        .then((res: any) => {
          if (res) {
            setAttendanceGraph(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  // const fetchTrackSheetCount = async () => {
  //   try {
  //     setIsLoading(true)
  //     await GetTrackSheetCount(selectChapterId, selectSession, selectGroup, selectLeader)
  //       .then((res: any) => {
  //         if (res) {
  //           setTrackSheetCount(res)
  //           setIsLoading(false)
  //         }
  //       })
  //       .catch((error: any) => {
  //         console.log(error, 'error !!')
  //         setIsLoading(false)
  //       })
  //   } catch (error) {
  //     console.log('error!!', error)
  //   }
  // }

  const copyTable = () => {
    try {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Create a range object to select the table content
      const range = document.createRange()
      range.selectNode(table)

      // Create a selection object and add the range
      const selection = window.getSelection() as any
      selection.removeAllRanges()
      selection.addRange(range)

      // Execute the copy command
      document.execCommand('copy')

      // Deselect the content
      selection.removeAllRanges()

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Optionally, provide feedback to the user
      alert('Table data copied to clipboard')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function ExportToExcel() {
    try {
      // Acquire Data (reference to the HTML table)
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Make the table briefly visible
      table.style.display = 'table'

      // Extract Data (create a workbook object from the table)
      var workbook = XLSX.utils.table_to_book(table)

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      // Process Data (add a new row)
      var ws = workbook.Sheets['Sheet1']
      // XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});

      // Package and Release Data (`writeFile` tries to write and save an XLSB file)
      XLSX.writeFile(workbook, 'sessionSummaryLeaderPointExcel.xlsb')
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }

  function convertTableToCSV() {
    try {
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Make the table briefly visible
      table.style.display = 'table'

      const rows = table.querySelectorAll('tr') as any
      let csv = []

      for (const row of rows) {
        const cells = row.querySelectorAll('td, th') as any
        const rowData = Array.from(cells).map((cell: any) => cell.textContent)
        csv.push(rowData.join(','))
      }

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'

      const csvContent = 'data:text/csv;charset=utf-8,' + csv.join('\n')
      const encodedURI = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedURI)
      link.setAttribute('download', 'sessionSummaryLeaderPointCsv.csv')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }
  const convertTableToPrint = () => {
    try {
      // Define the source table
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Make the table briefly visible
      table.style.display = 'table'

      html2canvas(document.querySelector('#copy_kt_table_summarysessions') as any).then(function (
        canvas: any
      ) {
        let imgWidth = 210
        let pageHeight = 290
        let imgHeight = (canvas.height * imgWidth) / canvas.width
        let heightLeft = imgHeight

        let doc = new jsPDF('p', 'mm')
        let position = 0
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let imgData = encodeURIComponent(pageData)
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        doc.setLineWidth(5)
        doc.setDrawColor(255, 255, 255)
        doc.rect(0, 0, 210, 295)
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight
          doc.addPage()
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
          doc.setLineWidth(5)
          doc.setDrawColor(255, 255, 255)
          doc.rect(0, 0, 210, 295)
          heightLeft -= pageHeight
        }
        doc.autoPrint()
        //This is a key for printing
        doc.output('dataurlnewwindow')
      })

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    } catch (err) {
      // Get a reference to the table
      let table = document.getElementById('copy_kt_table_summarysessions') as any

      // Hide the table again (if it was originally hidden)
      table.style.display = 'none'
    }
  }



  useEffect(() => {
    // fetchLeaderPoints()
    // fetchTrackSheetCount()
    fetchGetAverageFeedback()
    fetchGetAttendanceGraph()
    fetchGroupAll()
    fetchGetInvitedAttendedRatio()
    fetchGetInvitedAttended()
    fetchChapterDetail()
    fetchSessionDetail()
    fetchTopPerformersData()
    if (!selectChapterId) {
      // setSelectChapterId('4')
      setSelectChapterId('')
    }
    if (!selectSession) {
      setSelectSession('')
    }
    if (!selectGroup) {
      setSelectGroup('')
    }
    if (!selectLeader) {
      setSelectLeader('')
    }
  }, [selectChapterId, selectSession, selectGroup, selectLeader])

  const fetchGroupAll = async () => {
    try {
      await GroupsDropDown()
        .then((res: any) => {
          if (res) {
            setGroupData(res)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
        })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchGetInvitedAttendedRatio = async () => {
    try {
      await GetInvitedAttendedJoinedRatio(selectChapterId, selectSession, selectGroup, selectLeader)
        .then((res: any) => {
          if (res) {
            setInviteAttendedJoinRatio(res)
            setIsLoading(false)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
          setIsLoading(false)
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchGetInvitedAttended = async () => {
    try {
      await GetInvitedAttended(selectChapterId, selectSession, selectGroup, selectLeader)
        .then((res: any) => {
          if (res) {

            setInviteAttendedJoin(res)

            let conversionRatio = (res.visitorJoined / res.visitorAttendent) * 100

            let percentage = 0;

            if (typeof res?.visitorJoined === 'number' && typeof res?.visitorAttendent === 'number' &&
              res?.visitorJoined != 0 && res?.visitorAttendent != 0
            ) {
              percentage = (res?.visitorJoined / res?.visitorAttendent) * 100;
            }

            setConversionRatio(percentage.toFixed(2))


            setIsLoading(false)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
          setIsLoading(false)
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const fetchTopPerformersData = async () => {
    try {
      setIsLoading(true)
      await TopPerformersData(selectChapterId, selectSession, selectGroup, selectLeader)
        .then((res: any) => {
          if (res) {
            setTop5Performers(res)
            setIsLoading(false)
          }
        })
        .catch((error: any) => {
          console.log(error, 'error !!')
          setIsLoading(false)
        })
    } catch (error) {
      console.log('error!!', error)
    }
  }

  const viewImageForServer = async (pdf: any) => {
      try{
        if(pdf){
          let file1 = await fetch(`${API_URL}/containers/userprofile/download/${pdf}`).then(response => {
            return response.ok
          }).catch((err)=>{return false})
           
          if(file1){
            return `${API_URL}/containers/userprofile/download/${pdf}`
          }
      
          let file2 = await fetch(`${API_URL}/containers/public/download/${pdf}`).then(response => {
            return response.ok
          }).catch((err)=>{return false})
      
          if(file2){
            return `${API_URL}/containers/public/download/${pdf}`
          }
        }
        else{
          return toAbsoluteUrl('./media/avatars/default-user.png')
        }
        return toAbsoluteUrl('./media/avatars/default-user.png')
      }
      catch(err){
        return toAbsoluteUrl('./media/avatars/default-user.png')
      }
  }

  return (
    <>
      <h1 className='inv-hst'> Session Summary </h1>
      <div className='my-8 py-8 px-4 px-sm-6 card mx-3 mx-md-0 ilead__card'>
        <div className='row mx-0'>
          {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <h3 className="text-primary fs-5 fw-bold"> Chapter </h3>
                <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
                  <option selected>All</option>
                  <option value="1">Eklavya</option>
                </select>
              </div> */}

          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
            <h3 className='text-primary fs-5 fw-bold'> Chapter </h3>
            <select
              className='form-select form-select-lg mb-3 mt-3'
              aria-label='.form-select-lg example'
              value={selectChapterId}
              // onChange={(e) => setSelectChapterId(e.target.value)}
              onChange={(e: any) => {
                let filter = state?.filter ?? {}
                filter['chapter_id'] = e.target.value
                updateState({ ...state, ...{ filter: filter } })
                setSelectChapterId(e.target.value)
              }}
            >
              <option value="">All</option>
              {chapterData?.map((item: any, index: any) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3'>
            <h3 className='text-primary fs-5 fw-bold'> Session </h3>
            {/* <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
                  <option selected>All</option>
                  <option value="1">23-11-2018</option>
                  <option value="1">07-12-2018</option>
                </select> */}
            <select
              className='form-select form-select-lg mb-3 mt-3'
              aria-label='.form-select-lg example'
              value={selectSession}
              // onChange={(e) => setSelectSession(e.target.value)}
              onChange={(e: any) => {
                let filter = state?.filter ?? {}
                filter['event_id'] = e.target.value
                updateState({ ...state, ...{ filter: filter } })
                setSelectSession(e.target.value)
              }}
            >
              <option value="">All</option>
              {sessionData?.map((item: any, index: any) => (
                // <option value={item.id}>{item.name}</option>
                // <option value={item.id}>{item.formattedDate}</option>
                <option value={item.id}>{moment(item?.event_date).format('DD-MM-YYYY')}</option>
              ))}
            </select>
          </div>
          {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <h3 className="text-primary fs-5 fw-bold"> Group  </h3>
                <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
                  <option selected>All</option>
                  <option value="1">Lakshya</option>
                  <option value="1">Atmasodh</option>
                  <option value="1">Chanakya</option>
                  <option value="1">Urja</option>
                  <option value="1">Anushasan</option>
                  <option value="1">Pragati</option>
                </select>
              </div> */}

          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-5'>
            <h3 className='text-primary fs-5 fw-bold'> Group </h3>
            <select
              className='form-select form-select-lg mb-3 mt-3'
              aria-label='.form-select-lg example'
              value={selectGroup}
              // onChange={(e) => setSelectGroup(e.target.value)}
              onChange={(e: any) => {
                let filter = state?.filter ?? {}
                filter['group_id'] = e.target.value
                updateState({ ...state, ...{ filter: filter } })
                setSelectGroup(e.target.value)
              }}
            >
              <option value="">All</option>
              {groupData?.map((item: any, index: any) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <h3 className="text-primary fs-5 fw-bold"> Leader </h3>
            <select
              className="form-select form-select-lg mb-3 mt-3"
              aria-label=".form-select-lg example"
              value={selectLeader}
              onChange={(e) => setSelectLeader(e.target.value)}
            // onChange={(e: any) => {
            //   let filter = state?.filter ?? {}
            //   filter['leader'] = e.target.value
            //   updateState({ ...state, ...{ filter: filter } })
            // }}
            >
              <option value="">All</option>
              {leaderFilterList?.map((item: any, index: any) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='my-8 py-8 px-4 px-sm-6 card mx-3 mx-md-0 ilead__card'>
        <div className='row mx-0 mb-6'>
          <div className='col-md-12 col-lg-6 col-xl-6 px-3'>
            <h3 className='text-primary fs-3 fw-bold mb-6 mb-5'>
              {' '}
              Average Session Feedback Rating{' '}
            </h3>
          </div>
          <div className='col-md-12 col-lg-6 col-xl-6 text-end'>
            {/* <p className="mb-0 fs-5 text-info fw-semibold"> Value Addition Session Average Rating - 4.44 </p>
                <p className="mb-0 fs-5 text-info fw-semibold"> TYCA Session Average Rating - 3.87 </p>
                <p className="mb-0 fs-5 text-info fw-semibold"> Success Sharing Average Rating - 2.62 </p> */}
            <p className='mb-0 fs-5 text-info fw-semibold'>
              {' '}
              Value Addition Session Average Rating - {(getAverageFeedback?.averageva != null && typeof getAverageFeedback?.averageva !== "string")
                ? parseFloat(checkDisplayValue(getAverageFeedback?.averageva)).toFixed(2) : ""}{' '}
            </p>
            <p className='mb-0 fs-5 text-info fw-semibold'>
              {' '}
              TYCA Session Average Rating - {(getAverageFeedback?.averagetyca != null && typeof getAverageFeedback?.averagetyca !== "string")
                ? parseFloat(checkDisplayValue(getAverageFeedback?.averagetyca)).toFixed(2) : ""}{' '}
            </p>
            <p className='mb-0 fs-5 text-info fw-semibold'>
              {' '}
              Success Sharing Average Rating - {(getAverageFeedback?.averagess != null && typeof getAverageFeedback?.averagess !== "string")
                ? parseFloat(checkDisplayValue(getAverageFeedback?.averagess)).toFixed(2) : ""}{' '}
            </p>
          </div>
        </div>
        {/* <SummaryBarChart /> */}
        <SummaryBarChart getAverageFeedback={getAverageFeedback} />
      </div>

      <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
        <div className='row m-0 comn-field'>
          <h3 className='text-primary fs-4 fw-bold mb-6 ps-3'> Session Statistics </h3>
          <div className='table-responsive px-0'>
            <table className='table table-striped text-center'>
              <thead>
                <tr className='bg-primary text-white fw-bold fs-6'>
                  <th scope='col' colSpan={2} className='border-right border border-white-800 ps-3'>
                    Attendance
                  </th>
                  <th scope='col' colSpan={2} className='border-right border border-white-800'>
                    {' '}
                    Session Feedback{' '}
                  </th>
                  <th scope='col' colSpan={1}>
                    Tracksheet
                  </th>
                </tr>
                {/* <tr className='bg-primary text-white fw-bold fs-6'>
                  <th scope='col' className='border-right border border-white-800 ps-3 '>
                    {' '}
                    Presence{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Absence{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Received{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Submitted{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Note Submitted{' '}
                  </th>
                </tr> */}
                <tr className="bg-primary text-white fw-bold fs-6">
                  <th scope='col' className="border-right border border-white-800 ps-3 "> Presence </th>
                  <th scope='col' className="border-right border border-white-800"> Absence </th>
                  <th scope='col' className="border-right border border-white-800"> Received </th>
                  <th scope='col' className="border-right border border-white-800"> Not Received </th>
                  <th scope='col' className="border-right border border-white-800"> Submitted </th>
                </tr>
              </thead>
              <tbody className='text-dark'>
                <tr>
                  <td data-label='Presence' className='ps-3 '>
                    {' '}
                    {attandanceGraph ? `${attandanceGraph?.attendadantPercentage}` : 0}{' '}
                  </td>
                  <td data-label='Absence' className=''>
                    {' '}
                    {attandanceGraph ? `${attandanceGraph?.absentPercentage}` : 0}{' '}
                  </td>
                  <td data-label='Received' className=''>
                    {' '}
                    {getAverageFeedback?.given ?? 0}
                  </td>
                  <td data-label='Submitted' className=''>
                    {' '}
                    {getAverageFeedback?.notgiven ?? 0}{' '}
                  </td>
                  <td data-label='Not Submitted' className=''>
                    {' '}
                    {inviteAttendedJoin ? `${inviteAttendedJoin?.tracksheetSubmitted}` : 0}{' '}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='table-responsive px-0'>
            <table className='table table-striped mt-10 text-center'>
              <thead>
                <tr className='bg-primary text-white fw-bold fs-6'>
                  <th scope='col' colSpan={4} className='border-right border-white-800 ps-3'>
                    Visitor
                  </th>
                </tr>
                <tr className='bg-primary text-white fw-bold fs-6'>
                  <th scope='col' className='border-right border border-white-800 ps-3 '>
                    {' '}
                    Invited{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Attended{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Joined{' '}
                  </th>
                  <th scope='col' className='border-right border border-white-800'>
                    {' '}
                    Conversion Ratio{' '}
                  </th>
                </tr>
              </thead>
              <tbody className='text-dark'>
                <tr>
                  <td data-label='Invited' className='ps-3 '>
                    {' '}
                    {/* {inviteAttendedJoinRatio ? `${inviteAttendedJoinRatio?.visitorInvited}` : 0}{' '} */}
                    {inviteAttendedJoin ? `${inviteAttendedJoin?.visitorInvited}` : 0}{' '}
                    {/* inviteAttendedJoinRatio */}
                  </td>
                  <td data-label='Attended' className=''>
                    {' '}
                    {inviteAttendedJoin ? `${inviteAttendedJoin?.visitorAttendent}` : 0}{' '}
                  </td>
                  <td data-label='Joined' className=''>
                    {' '}
                    {inviteAttendedJoin ? `${inviteAttendedJoin?.visitorJoined}` : 0}{' '}
                  </td>
                  <td data-label='Conversion Ratio ' className=''>
                    {' '}
                    {/* {inviteAttendedJoin ? `${(inviteAttendedJoin?.visitorJoined/inviteAttendedJoin?.visitorAttendent)*100}` : 0}{' '} */}
                    {conversionRatio}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='my-8 py-8 px-0 '>
        <div className='d-flex flex-column flex-md-row justify-content-between mx-3 mx-md-0 comn-field'>
          <div className='flex-1 card p-5 ilead__card mb-5 me-3 '>
            <div className='row mx-0 mb-6 '>
              <div className='col-md-12  ps-0'>
                <h3 className='text-primary fs-3 fw-bold mb-6 mb-5'> Session Attendance </h3>
              </div>
            </div>
            {/* <SessionAttendancePieChart /> */}
            <SessionAttendancePieChart attandanceGraph={attandanceGraph} />
          </div>
          <div className='flex-1 card p-5 ilead__card mb-5'>
            <div className='row mx-0 mb-6'>
              <div className='col-md-12  ps-0'>
                <h3 className='text-primary fs-3 fw-bold mb-6 mb-5'> Visitor </h3>
              </div>
            </div>
            {/* <VisitorDoughnutChart /> */}
            {/* <VisitorDoughnutChart inviteAttendedJoin={inviteAttendedJoin} /> */}
            <VisitorDoughnutChart inviteAttendedJoinRatio={inviteAttendedJoinRatio} />
          </div>
        </div>
      </div>

      {/* <div className="my-8 py-8 px-4 px-sm-6 card mx-3 mx-md-0 ilead__card">
            <div className="row m-0 top-five-prfmr">
              <h3 className="text-primary fs-4 fw-bold mb-6"> Top 5 Performar </h3>

              <div className='all bigi-members d-flex overflow-y-auto comn-field'>
                <div className='member-box '>
                  <div className='member-pic'>
                    <Image
                      src={toAbsoluteUrl('/media/ilead/desert.jpg')}
                      className='position-absolute w-100 h-100 object-fit-cover'
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>973</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'>Sohan Manikot</h6>
                  </div>
                </div>
                <div className='member-box '>
                  <div className='member-pic'>
                    <Image
                      src={toAbsoluteUrl('/media/ilead/pulin-patel.jpg')}
                      className='position-absolute w-100 h-100 object-fit-cover'
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>973</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'>Punit Patel</h6>
                  </div>
                </div>
                <div className='member-box '>
                  <div className='member-pic'>
                    <Image
                      src={toAbsoluteUrl('/media/ilead/kirit-patel.jpg')}
                      className='position-absolute w-100 h-100 object-fit-cover'
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>973</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'>Kirit Patel </h6>
                  </div>
                </div>
                <div className='member-box '>
                  <div className='member-pic'>
                    <Image
                      src={toAbsoluteUrl('/media/ilead/raja-chainani.jpg')}
                      className='position-absolute w-100 h-100 object-fit-cover'
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>973</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'>Raja Chainani</h6>
                  </div>
                </div>
                <div className='member-box '>
                  <div className='member-pic'>
                    <Image
                      src={toAbsoluteUrl('/media/ilead/prashant-desai.jpg')}
                      className='position-absolute w-100 h-100 object-fit-cover'
                    ></Image>
                  </div>
                  <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                    <span className='points fw-bold ilead-text'>973</span>
                    <h6 className='text-white text-truncate fw-normal mb-0'> Prashant Desai </h6>
                  </div>
                </div>
              </div>

            </div>
          </div> */}

      <div className='my-8 pt-8 pb-5 px-4 px-sm-6 card mx-3 '>
        <div className='row m-0 top-five-prfmr'>
          <h3 className='text-primary fs-4 fw-bold mb-6'> Top 5 Performer </h3>

          <div className='all bigi-members d-flex overflow-y-auto comn-field'>
            {top5Performers?.length > 0 ? (
              <>
                {top5Performers?.map((item: any, index: any) => (
                  <div className='member-box '>
                    <div className='member-pic'>
                      <Image
                        src={`${API_URL}/containers/userprofile/download/${item?.profile}`}
                        onError={async ({ currentTarget }: any) => {
                          currentTarget.onerror = null
                          let image = await viewImageForServer(item?.profile)
                          currentTarget.src = image
                        }}
                        className='w-100'
                      />
                    </div>
                    <div className='member-details position-absolute p-3 w-100 bottom-0 d-flex flex-column justify-content-end'>
                      <span className='points fw-bold ilead-text'>{checkDisplayValue(item?.total_points)}</span>
                      <h6 className='text-white text-truncate fw-normal mb-0'>{checkDisplayValue(item?.name)}</h6>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <> </>
            )}
          </div>
        </div>
      </div>

      <div className='my-8 py-8 px-4 px-sm-6 card mx-3 mx-md-0 ilead__card'>
        <div className='row m-0 comn-field'>
          <h3 className='text-primary fs-4 fw-bold mb-6'> Leaders </h3>
          <div className='full-form-main'>
            <ul className='ng-tns-c6-0 list-style-none ps-0'>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>A - Attendance</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>VI - Visitor invition</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>VA - Visitor attandance</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>VJ - Visior joined</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>TG - Thank you given</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>TR - Thank you received</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                GMA - Group meeting attendance
              </li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>TU - TrackSheet Uploaded</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>FG - Feedback given</li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>
                ICIW - I CAN I WILL Message
              </li>
              <li className='ng-tns-c6-0 d-inline-block p-2 text-dark'>H - Help</li>
            </ul>
          </div>
          <div className='d-flex justify-content-start mb-3 ps-0 mt-6 '>
            <button
              type='button'
              className='btn px-5 py-2 me-3 btn-primary'
              onClick={() => {
                copyTable()
              }}
            >
              Copy
            </button>
            <button
              type='button'
              className='btn px-5 py-2 me-3 btn-success'
              onClick={() => {
                ExportToExcel()
              }}
            >
              Excel
            </button>
            <button
              type='button'
              className='btn px-5 py-2 me-3 btn-warning'
              onClick={() => {
                convertTableToCSV()
              }}
            >
              CSV
            </button>
            <button
              type='button'
              className='btn px-5 py-2 me-3 btn-info'
              onClick={() => {
                convertTableToPrint()
              }}
            >
              Print
            </button>
          </div>
          <div className='table-responsive '>
            <SummarysessionsListWrapper />
          </div>
        </div>
      </div>

      {console.log("addformikaddformik", addformik)}

      <div className='my-8 pt-8 px-4 px-sm-6 card mx-3 mx-md-0 ilead__card'>
        <h3 className='text-primary fs-3 fw-bold mb-6 ps-3'> Session Summary Report </h3>
        <form onSubmit={(e) => {
          e.preventDefault()
          addformik.handleSubmit()
        }}>
          <div className='row mx-0'>
            <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Chapter Name</h3>
              <select
                className='form-select form-select-lg mb-3 mt-3'
                aria-label='.form-select-lg example'
                onChange={(e) => {
                  addformik.setFieldValue('chapter_id', e.target.value)
                  fetchDataevent(e.target.value)
                }}
                value={addformik.values.chapter_id}
              >
                <option value=''>All</option>
                {datachapter?.map((i: any) => (
                  <option value={i.id}>{i?.name}</option>
                ))}
              </select>
              {addformik?.touched?.chapter_id && addformik?.errors?.chapter_id ?
                <b className='text-danger' style={{ fontSize: "17px" }}>
                  {addformik?.errors?.chapter_id}
                </b> : ""
              }
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Session Date</h3>
              <select
                className='form-select form-select-lg mb-3 mt-3'
                aria-label='.form-select-lg example'
                onChange={(e) => {
                  addformik.setFieldValue('event_id', e.target.value)
                }}
                value={addformik.values.event_id}
              >
                <option value=''>All</option>
                {dataevent?.map((i: any) => (
                  <option value={i.id}>{moment(i?.event_date).format('DD-MM-YYYY')}</option>
                ))}
              </select>
              {addformik?.touched?.event_id && addformik?.errors?.event_id ?
                <b className='text-danger' style={{ fontSize: "17px" }}>
                  {addformik?.errors?.event_id}
                </b> : ""
              }
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-5'>
              <h3 className='text-primary fs-5 fw-bold'> Upload </h3>
              <div className='input-group'>
                <input
                  type='file'
                  className='form-control'
                  id='inputGroupFile04'
                  aria-describedby='inputGroupFileAddon04'
                  aria-label='Upload'
                  onChange={(event: any) => {
                    const file = event.target.files?.[0]
                    if (file) {
                      uploadFile(file)
                    }
                  }}
                />
              </div>
              <div className='pt-4'>
                {addformik?.touched?.file && addformik?.errors?.file ?
                  <b className='text-danger' style={{ fontSize: "17px" }}>
                    {addformik?.errors?.file}
                  </b> : ""
                }
              </div>
              {isLoadingfile ? <div>Uploading File...</div> : ''}
            </div>

            <div className='col-sm-12 col-md-6 col-lg-4 col-xl-2 text-end mt-8 text-end mt-6'>
              <button
                type='submit'
                className='btn px-5 py-2  btn-success'
                disabled={
                  isLoadingfile || addformik.isSubmitting
                }
              >
                {' '}
                Submit{' '}
              </button>
            </div>
          </div>
        </form>

        <table className='table table-striped text-center mx-3 mt-6'>
          <thead>
            <tr className='bg-primary text-white fw-bold fs-6'>
              <th scope='col' className='border-right border border-white-800 ps-3'>
                Chapter Name
              </th>
              <th scope='col' className='border-right border border-white-800'>
                Session Date
              </th>
              <th scope='col' className='border-right border border-white-800'>
                File
              </th>
            </tr>
          </thead>
          <tbody className='text-dark'>
            {datassr?.map((item: any, index: any) => (
              <tr key={index}>
                <td className='ps-3 '> {checkDisplayValue(item?.chapters?.name)} </td>
                <td className=''>
                  {' '}
                  {item?.eventdate?.event_date
                    ? moment(checkDisplayValue(item?.eventdate?.event_date)).format('DD-MM-YYYY')
                    : '-'}{' '}
                </td>
                <td className=' d-flex align-items-center'>
                  {' '}
                  <span className='me-1 me-sm-2'>
                    <Link
                      to='#'
                      onClick={() => (item?.file ? handleButtonClick(item?.file) : '')}
                    >
                      <img
                        src={toAbsoluteUrl('/media/svg/files/file.png')}
                        style={{ width: '30px' }}
                        alt=''
                      />
                    </Link>{' '}
                  </span>
                  <span
                    className='ng-tns-c8-1 btn btn-light-warning rounded-circle py-2 px-3 btn-sm'
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <i className='fa-regular fa-pen-to-square pe-0 fs-6'></i>
                    <input
                      className='form-control hide_file ng-pristine ng-valid ng-touched'
                      accept=' .xls. .xlsx, .doc, .docx, .ppt, .pptx,
                      image/png,
                      image/jpeg, image/jpg, application/pdf,
                      application/docx ,
                      application/xls,
                      application/xlsx,
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.ms-excel,
                      application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      text/csv,
                      application/vnd.ms-powerpoint,
                      application/ppt,
                      application/pptx,
                      application/msword'
                      id='file'
                      name='file'
                      type='file'
                      onChange={(event: any) => {
                        const file = event.target.files?.[0]
                        if (file) {
                          editFile(file, item.id)
                        }
                      }}
                    />
                  </span>{' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>

  )
}

const SummarySession = () => {
  return <>
    <QueryRequestProvider>
      <QueryResponseProvider>
        <SummarySessions />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}



export { SummarySession }
